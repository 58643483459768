const accountFeatures = [
  {
    title: 'Transferências TED e PIX gratuitas',
    description: 'Faça e receba transferências para todos os bancos, gratuitamente pelo app.',
    open: true,
  },
  {
    title: 'Emissão gratuita de boletos',
    description: 'Pague apenas R$1,90 por boleto, a partir da 100ª cobrança compensada.',
    open: false,
  },
  {
    title: 'Projeção do fluxo de caixa',
    description: 'Controle suas despesas e receitas, e entenda a projeção bancária do seu caixa facilmente.',
    open: false,
  },
  {
    title: 'Pagamento de contas e impostos',
    description: 'Pague contas e faturas com um toque — a qualquer hora, em qualquer lugar.',
    open: false,
  },
  {
    title: 'Cartão empresarial Visa sem anuidade',
    description: 'Aceito em todo o mundo. Saque em mais de 23 mil caixas da rede Banco24Horas.',
    open: false,
  },
  {
    title: 'Integrado à contabilidade',
    description: 'Use um de nossos parceiros integrados ou exporte o seu extrato facilmente.',
    open: false,
  },
  {
    title: 'Suporte no aplicativo',
    description: 'Converse com nossa equipe de onde estiver, pelo app ou pelo whatsapp.',
    open: false,
  },
  {
    title: 'Acesso múltiplo para sócios',
    description: 'Todos os sócios poderão realizar operações dentro da sua conta empresarial.',
    open: false,
  },
];

export default accountFeatures;
