import styled from 'styled-components'

export const Item = styled.div`
  border-radius: 5px;
  padding-left: 70px;
  position: relative;
  transition: all .3s ease-in-out;
  width: 100%;
  cursor: pointer;

  .title {
    color: #202020;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
  }

  .description {
    display: none;

    span {
      color: #888E93;
      line-height: 24px;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease-in-out 1s;
    }
  }

  &.feature-item--open {
    background-color: #FFF;
    box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.06);
    padding: 24px 32px 24px 70px;
    max-height: auto;

    .title {
      color: #FE3E6D;
    }

    .description {
      display: block;

      span {
        opacity: 1;
        visibility: visible;
        transition: all .3s ease-in-out 1s;
      }
    }

    &:before {
      opacity: 1;
      visibility: visible;
      max-height: 84px;
      transition: max-height .3s ease-in-out;
    }
  }

  &:before {
    background-color: #FE3E6D;
    border-radius: 5px;
    content: '';
    height: 100%;
    max-height: 0;
    position: absolute;
    width: 4px;
    left: 32px;
    opacity: 0;
    visibility: hidden;
  }
`
