import React, { useState } from 'react'
import Img from 'gatsby-image'

import accountFeatures from '../../../data/account-features'

import FeatureItem from '../../../components/FeatureItem'

import * as Styled from './styled'
import getFluidImage from '../../../hooks/getFluidImage'
import { imageDesktop } from './constants'

const FeaturesDesktop = (): JSX.Element => {
  const [state, setState] = useState({ data: accountFeatures })

  const toogle = (index: number) => {
    const newState = state.data.map((item, i) => {
      item.open = false
      if (i === index) item.open = !item.open
      return item
    })

    setState({ ...state, data: [...newState] })
  };

  return (
    <Styled.ListFeaturesWrapper>
      <Styled.ListFeaturesImage>
        <div>
          {state.data.map((item, i) => item.open && <Img key={`desktop-image-${i}`} fluid={getFluidImage(imageDesktop[`image${i + 1}`])} />)}
        </div>
      </Styled.ListFeaturesImage>
      <Styled.ListFeatures>
        <>
          {state.data.map((item, i) => (
            <li key={`desktop-feature-${item.title}`} onClick={() => toogle(i)}>
              <FeatureItem className={item.open ? 'feature-item--open' : ''}>
                <h3 className="title">{item.title}</h3>
                <div className="description">
                  <span>{item.description}</span>
                </div>
              </FeatureItem>
            </li>
          ))}
        </>
      </Styled.ListFeatures>
    </Styled.ListFeaturesWrapper>
  )
}

export default FeaturesDesktop
