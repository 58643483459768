import React from 'react'

import Heading from '../../../components/Heading'

import FeaturesMobile from './features-mobile'
import FeaturesDesktop from './features-desktop'

import * as Styled from './styled'

const SectionFeatureAccount = (): JSX.Element => (
  <Styled.Wrapper>
    <div className="container">
      <Styled.Content>
        <Styled.TitleWrapper>
          <Heading headingLevel="h2" size="xlarge">Conta Digital PJ Poderosamente simples.</Heading>
        </Styled.TitleWrapper>
        <Styled.SmallTitle>
          Tudo o que você precisa para gerenciar as finanças do seu negócio. Tudo em um único lugar.
        </Styled.SmallTitle>
        <FeaturesDesktop />
        <FeaturesMobile />
      </Styled.Content>
    </div>
  </Styled.Wrapper>
)

export default SectionFeatureAccount
