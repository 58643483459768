import React from 'react';

import * as Styled from './styled'

import Heading from '../../../components/Heading'

const SectionTwo = (): JSX.Element => (
  <Styled.Wrapper>
    <div className="container">
      <Styled.Content>
        <Styled.HeadingWrapper>
          <Heading headingLevel="h2" size="xlarge" dark>
            Tudo o que a sua empresa precisa de um banco digital PJ, só que muito mais fácil.
          </Heading>
        </Styled.HeadingWrapper>
        <Styled.Text>
          Uma conta, um cartão <Styled.NegativeText>— só para negócios.</Styled.NegativeText><br />
          Sem burocracia, sem taxas abusivas e sem papelada.
        </Styled.Text>
      </Styled.Content>
    </div>
  </Styled.Wrapper>
)

export default SectionTwo
