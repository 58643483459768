import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Heading from '../../../components/Heading'

import * as Styled from './styled'

const SectionAboutAccount = (): JSX.Element => {
  const { firstImageMobile, firstImageDesktop, secondImageMobile, secondImageDesktop } = useStaticQuery(
    graphql`
      query {
        firstImageMobile: file(relativePath: { eq: "new/account/first-image-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 490, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        firstImageDesktop: file(relativePath: { eq: "new/account/first-image-desktop.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        secondImageMobile: file(relativePath: { eq: "new/account/second-image-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 490, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        secondImageDesktop: file(relativePath: { eq: "new/account/second-image-desktop.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const getSource = (mobile: any, desktop: any) => {
    return [
      mobile.childImageSharp.fluid,
      {
        ...desktop.childImageSharp.fluid,
        media: `(min-width: 500px)`,
      },
    ]
  }

  return (
    <Styled.Wrapper className="container container--no-mobile-margin">
      <Styled.ComplementWrapper>
        <Styled.WrapperTextLeft>
          <Heading headingLevel="h2" size="large">Menos esforço. Mais tempo pra cuidar do que importa.</Heading>
          <Styled.Text>Se seu negócio é pequeno, há uma boa chance de que seu tempo também seja. A Cora simplifica a vida de pessoas empreendedoras que precisam de mais tempo e facilidade para cuidar dos seus negócios.</Styled.Text>
        </Styled.WrapperTextLeft>
        <Styled.WrapperImageRight>
          <Img fluid={getSource(firstImageMobile, firstImageDesktop)} />
        </Styled.WrapperImageRight>
      </Styled.ComplementWrapper>
      <Styled.ComplementWrapper>
        <Styled.WrapperImageLeft>
          <Img fluid={getSource(secondImageMobile, secondImageDesktop)} />
        </Styled.WrapperImageLeft>
        <Styled.WrapperTextRight>
          <Heading headingLevel="h2" size="large">Assuma o controle financeiro com seu celular.</Heading>
          <Styled.Text>
            A Cora elimina a complexidade do dia a dia financeiro da sua empresa. Despesas, cobranças, pagamentos, faturamento e impostos em um só lugar. Resolva tudo pelo app. Filas e agências, nunca mais!
          </Styled.Text>
        </Styled.WrapperTextRight>
      </Styled.ComplementWrapper>
    </Styled.Wrapper>
  )
}

export default SectionAboutAccount
