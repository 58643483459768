import React from 'react'

import Heading from '../../../components/Heading'

import * as Styled from './styled'

const SectionSix = (): JSX.Element => (
  <Styled.Section>
    <div className="container">
      <Styled.TitleWrapper>
        <Heading headingLevel="h3" size="xlarge">Não aceite menos do que a sua empresa merece</Heading>
      </Styled.TitleWrapper>
      <Styled.TextWrapper>
        Todos os dias você coloca seu esforço, seu tempo e seu coração nela <small>— sua empresa merece a Cora.</small>
      </Styled.TextWrapper>
    </div>
    <Styled.Shadow />
  </Styled.Section>
)

export default SectionSix
