import React, { useState } from 'react'
import { Check } from '@corabank/icons'

import Heading from '../../../components/Heading'

import * as Styled from './styled'
import { accountInfos } from './data'
import { IAccountInfos, IContentItem } from './interface'

const SectionBetweenAccounts = (): JSX.Element => {
  const [active, setActive] = useState(0)

  return (
    <Styled.Section>
      <div className="container">
        <Styled.TitleWrapper>
          <Heading headingLevel="h2" size="xlarge" center>Como nenhuma outra conta PJ</Heading>
          <Styled.Text>
            Sua empresa precisa de um banco tradicional menos do que você imagina.
          </Styled.Text>
        </Styled.TitleWrapper>
        <Styled.ListWrapper>
          <Styled.SwitchWrapper>
            {
              accountInfos.map(({ title }: IAccountInfos, indexAcc: number) => (
                <Styled.SwitchButton
                  onClick={() => setActive(indexAcc)}
                  active={active === indexAcc}
                  key={`switch-${indexAcc}`}
                  typeButton={indexAcc === 0 ? 'primary' : 'secondary'}>
                  {title}
                </Styled.SwitchButton>
              ))
            }
          </Styled.SwitchWrapper>
          {accountInfos.map(({ items }: IAccountInfos, index: number) => (
            <Styled.TabContent active={active === index} key={`tabContent-accounts-${index}`}>
              <Styled.List>
                {items.map(({ info, disabled }: IContentItem, i: number): JSX.Element => (
                  <Styled.ListItem key={`tabContent-item-accounts-${i}`}>
                    <Styled.ListItemText>{info}</Styled.ListItemText>
                    <Styled.ListItemIcon>
                      <Styled.Icon disabled={disabled}>
                        <Check size="medium" color="#FFF" />
                      </Styled.Icon>
                    </Styled.ListItemIcon>
                  </Styled.ListItem>
                )
                )}
              </Styled.List>
            </Styled.TabContent>
          ))}
        </Styled.ListWrapper>
      </div>
    </Styled.Section>
  )
}

export default SectionBetweenAccounts
