import styled from 'styled-components'

export const Wrapper = styled.section`
  padding-top: 96px;

  @media screen and (min-width: 768px) {
    padding-top: 160px;
  }

  @media screen and (min-width: 1920px) {
    padding-top: 256px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;

  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
  }

  @media screen and (min-width: 1920px) {
    grid-column: 3/10;
  }
`

export const TitleWrapper = styled.div`
  max-width: 870px;

  @media screen and (min-width: 1920px) {
    max-width: 1040px;
  }
`

export const SmallTitle = styled.span`
  color: #888E93;
  display: block;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
`

export const ListFeaturesWrapper = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 24px;
    margin-top: 56px;
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: repeat(7, 1fr);
  }
`

export const ListFeaturesImage = styled.picture`
  display: flex;
  justify-content: center;
  grid-column: 1/5;

  > div {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 1/5;
  }

  @media screen and (min-width: 1920px) {
    grid-column: 1/4;
  }
`

export const ListFeatures = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 5/-1;
  height: 100%;

  @media screen and (min-width: 1920px) {
    grid-column: 4/-1;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const SlideWrapper = styled.div`
  margin-top: 70px;

  @media screen and (min-width: 768px) {
    max-width: 420px;
    align-self: center;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }

  .swiper-container {
    padding-bottom: 50px;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    transition: all .3s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    width: 16px;
    border-radius: 4px;
    background-color: #1EA4F3;
    transition: all .3s ease-in-out;
  }
`

export const SlideImage = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 420px;
  }
`

export const SlideTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  color: #FE3E6D;
  font-weight: 800;
  margin-top: 12px;
`

export const SlideDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #888E93;
  margin-top: 16px;
`
