import React from 'react'
import Img from "gatsby-image"
import getFluidImage from '../../../hooks/getFluidImage';

import * as Styled from './styled'

const Slide = ({ data }: SlideProps): JSX.Element => (
  <>
    <Styled.SlideImage>
      <Img fluid={getFluidImage(data.image)} />
    </Styled.SlideImage>
    <Styled.SlideTitle>{data.title}</Styled.SlideTitle>
    <Styled.SlideDescription>
      {data.description}
    </Styled.SlideDescription>
  </>
)

export default Slide
