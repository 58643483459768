import styled from 'styled-components'

export const Section = styled.section`
  margin: 100px 0 0;
`

export const TitleWrapper = styled.div`
  @media screen and (min-width: 768px) {
    grid-column: 1/-1;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 2/9;
  }
`

export const TextWrapper = styled.span`
  color: #888E93;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    grid-column: 1/10;
    font-size: 22px;
    line-height: 32px;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 2/9;
    max-width: 90%;
  }

  small {
    color: #FE3E6D;
  }
`

export const Shadow = styled.div`
  height: 150px;
  background: linear-gradient(rgba(222, 228, 233, 0) 0%, #DEE4E9 100%);

  @media screen and (min-width: 768px) {
    height: 200px;
  }

  @media screen and (min-width: 1280px) {
    height: 273px;
  }
`
