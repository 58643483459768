import React from 'react'
import { Link } from 'gatsby'
import { IAccountInfos } from './interface'

export const accountInfos: IAccountInfos[] = [
  {
    title: 'Conta Cora',
    items: [
      { info: 'Exclusiva para quem empreende.' },
      {
        info: (
          <>
            Transferências e{' '}
            <Link to="/boletos">emissão gratuita de boletos</Link> pelo app.
          </>
        ),
      },
      { info: 'Suporte rápido e amigável via whatsapp, chat no app e e-mail.' },
      {
        info:
          'Simples e digital, acesse a sua conta na hora que quiser, onde estiver.',
      },
      { info: 'Produtos construídos com base no seu feedback.' },
    ],
  },
  {
    title: 'Conta Tradicionais',
    items: [
      { info: 'Feitas para pessoas físicas e jurídicas.', disabled: true },
      {
        info: 'Tarifas abusivas e limites para todas transações.',
        disabled: true,
      },
      {
        info:
          'Demora no atendimento ao cliente, com longas filas de espera no telefone.',
        disabled: true,
      },
      { info: 'Agências, filas, papeladas e burocracia.', disabled: true },
      { info: 'Produtos que não resolvem os seus problemas.', disabled: true },
    ],
  },
]
