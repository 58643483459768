export const imageDesktop = {
  image1: 'slide-1-desktop.png',
  image2: 'slide-2-desktop.png',
  image3: 'slide-3-desktop.png',
  image4: 'slide-4-desktop.png',
  image5: 'slide-5-desktop.png',
  image6: 'slide-6-desktop.png',
  image7: 'slide-7-desktop.png',
  image8: 'slide-8-desktop.png',
} as any

export const imageMobile = {
  image1: 'slide-1-mobile.png',
  image2: 'slide-2-mobile.png',
  image3: 'slide-3-mobile.png',
  image4: 'slide-4-mobile.png',
  image5: 'slide-5-mobile.png',
  image6: 'slide-6-mobile.png',
  image7: 'slide-7-mobile.png',
  image8: 'slide-8-mobile.png',
} as any
