import styled from 'styled-components'

export const Wrapper = styled.section`
  padding-top: 96px;

  @media screen and (min-width: 768px) {
    padding-top: 120px;
  }

  @media screen and (min-width: 1280px) {
    padding-top: 160px;
  }

  @media screen and (min-width: 1920px) {
    padding-top: 290px;
  }
`

export const ComplementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: 1/-1;
    gap: 24px;

    &:not(:last-child) {
      margin-bottom: 120px;
    }
  }

  @media screen and (min-width: 1280px) {
    &:not(:last-child) {
      margin-bottom: 220px;
    }
  }

  @media screen and (min-width: 1920px) {
    &:not(:last-child) {
      margin-bottom: 300px;
    }
  }
`

export const WrapperTextLeft = styled.div`
  order: 2;
  padding: 32px 24px 64px;

  @media screen and (min-width: 768px) {
    grid-column: 1/7;
    padding: 0;
    grid-row: 1;
  }
`

export const WrapperTextRight = styled.div`
  grid-column: 7/12;
  padding: 32px 24px 0;

  @media screen and (min-width: 768px) {
    grid-column: 7/-1;
    padding: 0;
    grid-row: 1;
  }
`

export const WrapperImageRight = styled.picture`
  width: 100%;

  @media screen and (min-width: 768px) {
    grid-column: 8/-1;
  }
`

export const WrapperImageLeft = styled.picture`
  width: 100%;

  @media screen and (min-width: 768px) {
    grid-column: 1/6;
  }
`

export const Text = styled.div`
  color: #888E93;
  display: block;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
`
