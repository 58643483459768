import styled from 'styled-components'

export const Section = styled.section`
  position: relative;
  background: linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%);

  @media screen and (min-width: 768px) {
    padding: 90px 0;
  }
`

export const LeftSide = styled.div`
  padding: 24px;
  width: 100%;

  @media screen and (min-width: 375px) {
    padding: 24px 24px 32px;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1/7;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 2/7;
  }
`

export const RightSide = styled.div`
  padding: 32px 0 32px 24px;

  @media screen and (min-width: 768px) {
    grid-column: 7/-1;
    padding: 64px 0;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 8/-1;
    padding: 0;
  }

  @media screen and (min-width: 1920px) {
    grid-column: 7/-1;
    padding: 0;
  }

  .gatsby-image-wrapper {
    @media screen and (min-width: 768px) {
      margin-right: -32px;
    }

    @media screen and (min-width: 1280px) {
      margin-right: -64px;
    }
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 24px;

  &.sticky {
    position: fixed;
    top: 50px;
    width: calc(100vw - 48px);
    z-index: 2;
  }
`

export const Text = styled.span`
  display: flex;
  color: #888E93;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
`
