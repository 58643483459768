import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #FE3E6D;
  padding: 64px 0;

  @media screen and (min-width: 768px) {
    padding: 130px 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 330px 0 200px;
  }
`

export const Content = styled.div`
  grid-column: 2/12;
`

export const HeadingWrapper = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 60vw;
  }
`

export const Text = styled.span`
  color: #FFF;
  display: block;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
`

export const NegativeText = styled.span`
  color: #000;
`
