import React, { FC, ReactNode } from 'react';

import * as Styled from './styled'

interface FeatureItemProps {
  className: string
  children: ReactNode
}

const FeatureItem: FC<FeatureItemProps> = ({ className, children }: FeatureItemProps) => (
  <Styled.Item className={className}>
    {children}
  </Styled.Item>
)

export default FeatureItem
