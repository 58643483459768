import styled, { css } from 'styled-components'
import { IconProps, SwitchButtonProps, TabContentProps } from './interface'

export const Section = styled.section`
  padding: 96px 0 0;

  @media screen and (min-width: 768px) {
    padding: 160px 0 160px;
  }
`

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    grid-column: 2/12;
    text-align: center;
  }
`

export const Text = styled.span`
  color: #888e93;
  display: block;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
`

export const ListWrapper = styled.ul`
  grid-column: 2/12;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TabContent = styled.div<TabContentProps>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  width: 100%;

  @media screen and (min-width: 768px) {
    box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.08);
    border-radius: 5px;
  }
`

export const List = styled.ul`
  width: 100%;
`

export const ListItem = styled.li`
  display: flex;
  height: 152px;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.08);
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 768px) {
    box-shadow: none;
    border-radius: 0;

    &:not(:last-child) {
      margin-bottom: 0;
      border-bottom: 2px solid #f0f4f8;
    }
  }
`

export const ListItemText = styled.span`
  color: #888e93;
  font-size: 16px;
  line-height: 24px;
  padding: 0 19px;
  width: 70%;

  @media screen and (min-width: 768px) {
    padding: 34px 64px;
    width: 85%;
  }
`

export const ListItemIcon = styled.div`
  align-items: center;
  border-left: 2px solid #f0f4f8;
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;

  @media screen and (min-width: 768px) {
    width: 15%;
  }
`

export const Icon = styled.div<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fe3e6d;
  height: 40px;
  width: 40px;
  box-shadow: 3px 3px 20px rgba(254, 62, 109, 0.6);
  border-radius: 50%;

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: #c7cbcf;
          box-shadow: 3px 3px 20px rgba(199, 203, 207, 0.6);
        `
      : css`
          background-color: #fe3e6d;
          box-shadow: 3px 3px 20px rgba(254, 62, 109, 0.6);
        `}
`

export const SwitchWrapper = styled.div`
  display: flex;
  margin: 48px 0;
  border-radius: 50px;
  box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.08);
`

export const SwitchButton = styled.button<SwitchButtonProps>`
  padding: 12px 8px;
  font-size: 16px;
  line-height: 24px;
  color: #c7cbcf;
  font-weight: 800;
  border-radius: 50px;
  border: none;
  background-color: #fff;
  cursor: pointer;

  ${({ active, typeButton }) =>
    typeButton === 'primary'
      ? active
        ? css`
            background-color: #fe517b;
            color: #fff;
          `
        : css`
            background-color: #fff;
            color: #c7cbcf;
          `
      : ''}

  ${({ active, typeButton }) =>
    typeButton === 'secondary'
      ? active
        ? css`
            color: #fff;
            background-color: #1ea4f3;
          `
        : css`
            color: #c7cbcf;
            background-color: #fff;
          `
      : ''}

  @media (min-width: 375px) {
    padding: 16px;
  }

  @media (min-width: 768px) {
    padding: 16px 32px;
  }

  @media (min-width: 1280px) {
    font-size: 22px;
    line-height: 32px;
  }
`
