import React from 'react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper-bundle.css'

import accountFeatures from '../../../data/account-features'

import Slide from './slide'
import { imageMobile } from './constants'

import * as Styled from './styled'

SwiperCore.use([Pagination, A11y])

const FeaturesMobile = (): JSX.Element => (
  <Styled.SlideWrapper>
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
      breakpoints={{
        1024: {
          init: false,
          slidesPerView: 2,
        },
      }}
    >
      {accountFeatures.map((item, index) => {
        const data = Object.assign({}, item, { image: imageMobile[`image${index + 1}`] })

        return (
          <SwiperSlide key={`features-mobile-${index}`}>
            <Slide data={data} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  </Styled.SlideWrapper>
)

export default FeaturesMobile
